#App {
  height: 100vh;
  width: 100vw;
  background-color: #eeeeee;
}


#App #portrait-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}


#App #landscape-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow-y: scroll;
}

#App #landscape-container #sidebar-container {
  width: 400px;
  margin: 30px 20px;
  position: relative;
  flex-shrink: none;
}

#App #landscape-container #sidebar-container #sidebar-absolute {
  width: inherit;
  height: inherit;
  position: fixed;
}

#App #landscape-container #main-content-column {
  width: 1000px;
  margin: 30px 0px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
}


#App #main-content-container {
  width: 100%;
  height: 100%;
  background-color: white;
}

#App #cheeky-made-with-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 5px;
}

#App .made-with-text {
  margin: 0px;
  padding: 0px;
  color: grey;
  font-weight: 300;
  font-size: 0.7em;
  font-style: italic;
}

#App #react-logo {
  width: 50px;
  margin: 0px -7px; /* this logo has too much white space on the sides, so we want to bring that in */
}

h1, h2, h3, p {
  font-family: 'Roboto Slab', serif;
}
