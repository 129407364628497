#Navbar {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: white;
  border-top: 20px solid black;
  padding: 20px 20px 10px 20px;
}


#Navbar #title {
  text-align: center;
  margin-bottom: 5px;
}


#NavbarLink {
  width: calc(100% - 20px - 4px);
  margin-bottom: 8px;
  border: 2px solid black;
  padding: 6px 10px;
  background-color: white;
}

#NavbarLink.selected {
  width: calc(100% - 20px - 15px - 2px);
  border-right: 15px solid black;
}


#NavbarLink:hover {
  width: calc(100% - 20px - 15px - 2px);
  border-right: 15px solid black;
  cursor: pointer;
}

#NavbarLink #link-text {
  margin: 0px;
}
