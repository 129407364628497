#WorkExperienceSection {
  width: 100%;
  display: flex;
  flex-direction: column;
}

#RepeatingBannerText {
  width: 100%;
  overflow: hidden;
}

#RepeatingBannerText #text {
  white-space: pre;
  margin: 0px;
  margin-left: -40px;
  text-overflow: clip;
  width: 100%;
}


/* WorkCard ----------------------------------------------------------------- */

#WorkCard {
  width: 100%;
  border-radius: 5px;
}

#WorkCard #title-row {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#WorkCard #title-row #title {
  margin: 6px;
  color: white;
}

#WorkCard.landscape #content-container {
  width: calc(100% - 40px);
  padding: 50px 20px;
  display: flex;
}

#WorkCard.mobile #content-container {
  width: calc(100% - 40px);
  padding: 50px 20px;
  display: flex;
  flex-direction: column;
}

#WorkCard.mobile #positions-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 30px;
}


#WorkCard.landscape #positions-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

#WorkCard .position-name {
  color: white;
  margin: 0px;
  padding-right: 10px;
  font-size: 1.4em;
  text-align: right;
}

#WorkCard #focused-position-name {
  margin: 0px;
  color: white;
  padding: 5px 10px;
  font-size: 2.5em;
  text-align: right;
}

#WorkCard #content-container #at-column {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 10px;
}


#WorkCard #at-symbol {
  margin: 0px 20px;
}

#WorkCard #company-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#WorkCard #company-column #company-row {
  display: flex;
  align-items: center;
}

#WorkCard.mobile #company-column #company-row {
  display: flex;
  align-self: stretch;
  justify-content: center;
  align-items: center;
}

#WorkCard.mobile #link {
  display: flex;
}

#WorkCard.mobile #company-logo {
  height: 80px;
  margin: 0px;
}

#WorkCard.landscape #company-logo {
  padding: 5px 10px;
  border: 2px solid transparent;
  width: 350px;
  max-width: 80%;
}

#WorkCard #company-logo:hover {
  border: 2px solid transparent;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  cursor: pointer;
}

#WorkCard .small-row {
  height: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}

#WorkCard.mobile .small-row {
  justify-content: center;
}

#WorkCard #sub-text {
  color: white;
}

#WorkCard #sub-text-light {
  color: white;
  font-style: italic;
  margin-top: 30px;
  font-weight: 300;
}
