
/* Section ------------------------------------------------------------------ */

#SkillsSection {
  width: calc(100% - 60px);
  padding: 0px 30px;
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 10px;
}


#SkillsSection #column {
  flex-grow: 1;
  max-width: 50%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}


#SkillsSection #mobile-column {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}

/* Card --------------------------------------------------------------------- */

#SkillCard {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-direction: column;
  border-radius: 3px;
}

#SkillCard #top-bar {
  width: 100%;
  height: 15px;
}

#SkillCard #title-bar {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
}

#SkillCard #title-bar #title {
  margin: 0px 0px 0px 10px;
  padding: 0px;
  font-size: 1.4em;
}


#SkillCard #main-content-section {
  width: calc(100% - 4px - 40px);
  padding: 10px 20px 0px 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  flex-grow: 1;
}

#SkillCard #skill-card-option-wrapper-full-width {
  width: 100%;
}

#SkillCard #skill-card-option-wrapper-half-width {
  width: 40%;
  flex-grow: 1;
}



#SkillCardOption {
  display: flex;
  flex-direction: column;
}

#SkillCardOption #title {
  margin: 0px;
  padding: 0px;
}

#SkillCardOption #description {
  margin: 0px;
  padding: 0px;
  font-style: italic;
  color: grey;
  font-size: 0.9em;
}

#SkillCardOption #options-list {
  margin: 0px 0px 10px 0px;
}
