#HeaderSection {
  width: 100%;
  position: relative;
}

#HeaderSection #header-img {
  width: 100%;
}


#HeaderSection #info-container {
  position: absolute;
  left: 57%;
  top: 10%;
  width: 40%;
  color: white;
  display: flex;
  flex-direction: column;
}

#portrait-container #HeaderSection #info-container #name-text {
  font-size: 5.1vw;
  text-decoration: underline;
  margin: 3px;
}

#landscape-container #HeaderSection #info-container #name-text {
  font-size: 3.0em;
  text-decoration: underline;
  margin: 3px;
}


#portrait-container #HeaderSection #info-container .item-text {
  font-size: 3.2vw;
  margin: 3px;
}

#landscape-container #HeaderSection #info-container .item-text {
  font-size: 1.6em;
  margin: 3px;
}


#HeaderSection #info-container #icons-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

#HeaderSection #info-container #icons-container .icons-column {
  display: flex;
  flex-direction: column;
}

#portrait-container #HeaderSection #info-container #icons-container .logo_container {
  height: 5.5vw;
  width: 5.5vw;
  margin: 3vw 3vw;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}



#landscape-container #HeaderSection #info-container #icons-container .logo_container {
  height: 50px;
  width: 50px;
  margin: 30px 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

#HeaderSection .logo_container .logo_img {
  height: 95%;
  width: 95%;
}

#HeaderSection .logo_container .logo_img:hover {
  height: 100%;
  width: 100%;
}
