#HighlightsSection {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 120px;
}

#HighlightsSection .scroll-to-anchor {
  width: 100%;
  height: 1px;
}

#HighlightsSection #content-container {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


#HighlightsSection #highlight-title {
  font-size: 2.3em;
  text-align: center;
  margin-bottom: 40px;
}


#HighlightsSection .section-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 10px 0px 20px 0px;
}

#HighlightsSection .section-container .section-title {
  font-size: 1.5em;
  margin: 5px 0px;
}

#HighlightsSection .section-container .section-subtitle {
  font-weight: 300;
  margin: 0px;
}

#HighlightsSection .section-container .section-subtitle .highlight {
  color: green;
}

 #HighlightedProject {
  display: flex;
  flex-direction: column;
  width: 95%;
  margin-bottom: 20px;
}

#HighlightsSection #content-container {
  width: calc(100% - 4px - 40px);
  border: 2px solid black;
  padding: 5px 20px 20px 20px;
  border-top: 0px;
}

#HighlightsSection li {
  font-family: 'Roboto Slab', serif;
  font-weight: 300;
  margin-bottom: 4px;
}

#HighlightsSection #tech-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-top: 10px;
}

#HighlightsSection .tech {
  padding: 3px 10px;
  margin: 0px;
}


#HighlightsSection #link-button-row {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: stretch;

}

#HighlightsSection #go-to-site-link {
  text-decoration: none;
  border: 1px solid black;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}



#HighlightsSection.mobile #go-to-site-link {
  width: 95%;
}


#HighlightsSection.landscape #go-to-site-link {
  width: 50%;
}


#HighlightsSection #go-to-site-button {
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  color: white;
  font-size: 1.2em;
  text-align: center;
  margin: 0px;
}

#HighlightsSection #go-to-site-button:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
}


/* HighlightTitleCard ------------------------------------------------------- */

#HighlightTitleCard {
  padding: 10px 20px;
  background-color: grey;
  display: flex;
  align-items: center;
  border: 2px solid black;
  border-top: 8px solid black;
}

#HighlightTitleCard #logo {
  height: 100px;
  max-width: 40%;
}

#HighlightTitleCard .link {
  margin: 0px 4px;
  border: 1px solid black;
  padding: 2px 5px;
  border-radius: 3px;
  color: black;
  text-decoration: none;
}

#HighlightTitleCard .link:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

#HighlightTitleCard #right-side-container {
  margin-left: 10px;
}
#HighlightTitleCard #title {
  margin: 7px 0px;
}
