#AboutSection {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
}

#AboutSection #content-column {
  width: 80%;
}

#portrait-container #AboutSection #title {
  font-size: 6vw;
  margin: 0px;
}

#portrait-container .mobile #AboutSection #title {
  font-size: 2.5em;
  margin: 0px;
}


#portrait-container #AboutSection #subtitle {
  font-size: 3.5vw;
  font-weight: 200;
  margin: 0px;
}

#portrait-container .mobile #AboutSection #subtitle {
  font-size: 1.2em;
  font-weight: 300;
}

#portrait-container #AboutSection .paragraph {
  font-size: 2.0vw;
  font-weight: 200;
  margin: 30px 0px;
}

#portrait-container .mobile #AboutSection .paragraph {
  font-size: 1em;
  font-weight: 200;
  margin: 10px 0px;
}

#landscape-container #AboutSection #title {
  font-size: 3.7em;
  margin: 0px;
}

#landscape-container #AboutSection #subtitle {
  font-size: 2.2em;
  font-weight: 200;
  margin: 0px;
}

#landscape-container #AboutSection .paragraph {
  font-size: 1.1em;
  font-weight: 200;
  margin: 20px 0px;
}
